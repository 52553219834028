<template>
<div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 详情 -->
        <div class="main x_between">
            <!-- 左边 -->
            <div class="left" style="flex:1">
                <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="poster">
                <div class="column">
                    <div style="color:#fff;margin-top:30px">{{title}}</div>
                    <div style="color:#797979">日期：{{(date)}}  作者：{{author}}</div>
                    <div class="back" @click="backPage">返回列表</div>
                </div>
            </div>
            <!-- 右边 -->
            <div class="right" style="flex:2">
                <div class="content" >{{content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            title:"《悟空和展昭科技用户协议》",
            date:"2022-04-23",
            author:"成都悟空和展昭科技有限公司",
            content:"《悟空和展昭科技用户协议》（以下简称“本协议”）是由成都悟空和展昭科技有限公司（以下简称“悟空&展昭科技”）与您（包含注册、登录、使用、浏览悟空&展昭科技产品、服务的个人或组织）之间所订立的协议。 双方经协商，一致同意订立本服务协议。 您对本协议的接受即自愿接受全部条款的约束，包括接受悟空&展昭科技对任一服务条款随时所做的任何修改。 悟空&展昭科技有权对本协议包含的条款随时更新，更新后的协议条款一旦在网站公布即代替原来的协议条款，恕不再另行通知，您可在本网站查阅最新版协议条款。 在悟空&展昭科技修改本协议相关条款之后，若您不接受修改后的条款，请停止使用悟空&展昭科技提供的产品和服务，您继续使用悟空&展昭科技提供的产品和服务将被视为您已接受了修改后的协议。\n" +
                "一、【注册与账号】\n" +
                "1.1 悟空&展昭科技账号的所有权归悟空&展昭科技，您完成注册手续后，获得悟空&展昭科技账号的使用权。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。\n" +
                "1.2 您须对注册信息的真实性、合法性、有效性承担全部责任；不得冒充他人，不得利用他人的名义发布任何信息；不得恶意使用注册账号导致其他用户误认；否则悟空&展昭科技有权立即停止提供服务，由您独自承担由此而产生的一切法律责任。\n" +
                "1.3 您使用悟空&展昭科技产品、服务的行为必须合法，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何结果。您应对其中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。悟空&展昭科技无法且不会对因您行为而导致的任何损失或损害承担责任。\n" +
                "1.4 您须对自己的登录信息保密、不被其他人获取并使用并且对您在本网站账户下的所有行为负责。您必须将任何有可能触犯法律的，未授权使用或怀疑为未授权使用的行为在第一时间通知悟空&展昭科技，悟空&展昭科技不对您因未能遵守上述要求而造成的损失承担责任。\n" +
                "1.5 您不应将您的账号、密码转让、出售或出借予他人使用，若您授权他人使用账户，应对被授权人在该账号下发生所有行为负全部责任。\n" +
                "二、【服务内容】\n" +
                "2.1 悟空&展昭科技产品服务的具体内容由悟空&展昭科技根据实际情况提供，相关产品功能列表在相应网页公开，升级改动不会书面通知您。\n" +
                "2.2 除非本服务协议另有其它明示规定，悟空&展昭科技所推出的新产品、新功能、新服务，均受到本服务协议之规范。\n" +
                "2.3 为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。\n" +
                "2.4 鉴于网络服务的特殊性，您同意悟空&展昭科技有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。悟空&展昭科技不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。\n" +
                "2.5 悟空&展昭科技需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，悟空&展昭科技无需为此承担任何责任。悟空&展昭科技保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。\n" +
                "2.6 本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于悟空&展昭科技无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，悟空&展昭科技不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，悟空&展昭科技亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，悟空&展昭科技不承担任何责任。\n" +
                "2.7 您明确同意其使用悟空&展昭科技产品和服务所存在的风险将完全由其自己承担。您理解并接受下载或通过悟空&展昭科技服务取得的任何信息资料取决于您自己，并由您承担系统受损、资料丢失以及其它任何风险。悟空&展昭科技对在服务网上得到的任何商品购物服务、交易进程、招聘信息，都不作担保。\n" +
                "2.8 悟空&展昭科技有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，悟空&展昭科技对您和任何第三人均无需承担任何责任。\n" +
                "三、【第三方产品和服务】\n" +
                "3.1 本服务可能包含第三方提供的产品或服务。当用户使用第三方提供的产品或服务时，可能会另有相关的协议或规则，您同样应当认真阅读并遵守。\n" +
                "3.2 您须理解并同意：为了向您提供更好的用户体验，当您在使用第三方服务时，第三方可能会接触、使用到您的昵称、头像以及在第三方服务中的相关操作信息等信息。\n" +
                "3.3 如您在使用第三方产品或服务时发生任何纠纷的，请与第三方直接联系，悟空&展昭科技不承担任何责任，但根据需要会依法提供必要的协助。\n" +
                "四、【权利限制】\n" +
                "4.1 您不得对悟空&展昭科技的产品、程序及服务（包括但不限于内容或产品中的广告或赞助内容）进行任何形式的许可、出售、租赁、转让、发行或做其他商业用途；\n" +
                "4.2 您不得以创建相同或竞争服务为目的访问或使用悟空&展昭科技产品、程序及服务；\n" +
                "4.3 除非法律明文规定，否则您不得对悟空&展昭科技产品、程序及服务（包括但不限于内容或产品中的广告或赞助内容）的任何部分以任何形式或方法进行复制、发行、再版、下载、显示、张贴、修改、翻译、合并、利用、分解或反向编译等；\n" +
                "4.4 您在使用悟空&展昭科技产品和服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶\n" +
                "(1) 反对宪法所确定的基本原则的；\n" +
                "(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；\n" +
                "(3) 损害国家荣誉和利益的；\n" +
                "(4) 煽动民族仇恨、民族歧视、破坏民族团结的；\n" +
                "(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；\n" +
                "(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；\n" +
                "(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；\n" +
                "(8) 侮辱或者诽谤他人，侵害他人合法权利的；\n" +
                "(9) 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；\n" +
                "(10) 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；\n" +
                "4.5 您已同意不在或不利用悟空&展昭科技产品、程序及服务从事下列行为：\n" +
                "(1) 上传或发布电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；\n" +
                "(2) 未授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；\n" +
                "(3) 禁用本网站的网络连接，给本网站造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；\n" +
                "(4) 在未授权的情况下，尝试访问本网站、服务器或本网站的网络链接；\n" +
                "(5) 干扰、破坏其它用户正常使用悟空&展昭科技产品、程序及服务。\n" +
                "4.6 您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿悟空&展昭科技与合作公司、关联公司，并使之免受损害。对此，悟空&展昭科技有权视您的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收悟空&展昭科技账号、追究法律责任等措施。对恶意注册悟空&展昭科技账号或利用悟空&展昭科技账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，悟空&展昭科技有权回收其账号。同时，悟空&展昭科技公司会视司法部门的要求，协助调查。\n" +
                "4.7 您须对自己在使用悟空&展昭科技产品和服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在悟空&展昭科技首先承担了因您行为导致的行政处罚或侵权损害赔偿责任后，您应给予悟空&展昭科技等额的赔偿。\n" +
                "五、【风险与免责】\n" +
                "基于互联网的开放性和特殊性，悟空&展昭科技特别提醒您谨慎注意以下风险：\n" +
                "5.1 本服务仅提供一个账号管理的平台，您应当对其他用户使用本服务所产生的信息进行独立判断并承担因依赖该内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险以及因用户个人信息被其他用户获知而带来的风险。您了解并确认，悟空&展昭科技不对本服务用户之间的纠纷承担任何责任。\n" +
                "5.2 请您切勿在使用本服务时透露任何涉及财产、本服务账号及密码等重要信息，否则，由此带来的损失由您自行承担。\n" +
                "5.3 由于您或其他用户违反本服务相关规则导致被悟空&展昭科技依约处理的，可能会对您在本服务中的内容、信息等数据造成影响。\n" +
                "六、【不可抗力】\n" +
                "6.1 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，悟空&展昭科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失悟空&展昭科技在法律允许的范围内免责。\n" +
                "6.2 在法律允许的范围内，悟空&展昭科技对以下情形导致的服务中断或受阻不承担责任：\n" +
                "(1) 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；\n" +
                "(2) 您或悟空&展昭科技的电脑软件、系统、硬件和通信线路出现故障；\n" +
                "(3) 您操作不当；\n" +
                "(4) 您通过非悟空&展昭科技授权的方式使用本服务；\n" +
                "(5) 其他悟空&展昭科技无法控制或合理预见的情形。\n" +
                "七、【知识产权】\n" +
                "7.1 悟空&展昭科技在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归悟空&展昭科技所有，您在使用本服务中所产生的内容的知识产权归您或相关权利人所有。\n" +
                "7.2 除另有特别声明外，悟空&展昭科技提供本服务时所依托软件的著作权、专利权及其他知识产权均归悟空&展昭科技所有。\n" +
                "7.3悟空&展昭科技在本服务中所使用的“悟空&展昭科技”、“BIMOM” 等商业标识，其商标权归悟空&展昭科技所有。\n" +
                "7.4 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经悟空&展昭科技、您或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。\n" +
                "7.5 除前述规定以外，如果您认为有人复制并在悟空&展昭科技服务上发布您的作品，并已构成对您著作权的侵犯，请及时与我们联系，并提供包含如下信息的书面通知：\n" +
                "(1) 证明您作为涉嫌侵权内容所有者的权属证明；\n" +
                "(2) 明确的身份证明、住址、联系方式；\n" +
                "(3) 涉嫌侵权内容在悟空&展昭科技用户中心上的位置；\n" +
                "(4) 您声称遭受侵犯的著作权作品的描述；\n" +
                "(5) 您著作权遭受侵犯的相关证明；\n" +
                "(6) 在同意承担伪证处罚之后果的前提下，出具书面陈述以声明您在通知中所述内容是准确和真实的。录或与其它产品捆绑使用销售。\n" +
                "八、【服务的变更、中断、终止】\n" +
                "8.1 悟空&展昭科技可能会对服务内容进行变更，也可能会中断、中止或终止服务。\n" +
                "8.2 如发生下列任何一种情形，悟空&展昭科技有权不经通知而中断或终止向您提供的服务：\n" +
                "(1) 根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；\n" +
                "(2) 您违反相关法律法规或本协议的约定；\n" +
                "(3) 按照法律规定或主管部门的要求；\n" +
                "(4) 出于安全的原因或其他必要的情形。\n" +
                "(5) 根据约定您使用收费项目。若您未按时足额付费，悟空&展昭科技有权中断、中止或终止提供服务。\n" +
                "8.3 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，悟空&展昭科技可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，悟空&展昭科技没有义务向您返还数据。\n" +
                "九、【法律适用与管辖】\n" +
                "9.1 本协议签订地为中华人民共和国北京市朝阳区。\n" +
                "9.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。\n" +
                "9.3 若您和悟空&展昭科技之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。\n" +
                "9.4 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。\n" +
                "十、【其他】\n" +
                "10.1 请查阅我们的《隐私条款》，《隐私条款》为本协议效力不可分割的一部分，且与本协议具有同等法律效力。\n" +
                "10.2 如果您对本协议或本服务有意见或建议，可与悟空&展昭科技用户中心客服联系。\n" +
                "10.3 联系方式:\n" +
                "• 地址：四川省成都市高新区天府五街\n" +
                "• 邮编：610000\n" +
                "• 电话：15959358326\n" +
                "• 电子信箱：fanbaoron@163.com\n" +
                "\n",
            url:""
            }
    },
    components:{
        topBanner
    },
    mounted(){
        //this.title= this.$route.query.title;
        //this.findDynamics();

    },
    methods:{
        formatDate1(data){
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },
        backPage(){
            this.$router.go(-1)
        },
        backHome(){
            this.$router.push("/")
        },
        findDynamics(){
            let config = {
                headers : {
                    'Content-Type':'application/json'
                }
            };
            const param={
                title:this.title,
            };
            this.axios.post(this.VUE_APP_BASE_API+'/CompanyDynamics/getDynamic',param,config).then((res)=>{
                if(res.data.resultCode==0){
                    this.tabledatas=[];
                    res.data.resultData.forEach((val)=>{
                        let data={
                            title:val.title,
                            author:val.author,
                            content:val.content,
                            date:val.date
                        }
                        this.tabledatas.push(data);
                    });
                    this.title=this.tabledatas[0].title;
                    this.author=this.tabledatas[0].author;
                    this.content=this.tabledatas[0].content;
                    this.date=this.tabledatas[0].date;
                    this.url="http://www.wkhzz.com:8085/BimomWebSource/"+this.title+".png";
                }else{
                    alert(res.data.resultMsg);
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.main{
    margin-top: 60px;
    .left{
        font-family: '幼圆 Bold', '幼圆 Regular', '幼圆', sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 30px;
        position: relative;
        .poster{
            width: 100%;
            border-radius: 10px;
        }
        .column{
            div{
                margin-top: 40px;
            }
            .back{
                position: absolute;
                bottom: 0;
                cursor: pointer;
                color:#797979;
                &:hover{
                    color: #02A7F0;
                }
            }
        }
        
    }
    .right{
        width: 100%;
        height: 750px;
        overflow: auto;
        .content{
            color: #fff;
            font-family: '幼圆', sans-serif;
            padding: 0 40px;
            font-size: 22px;
            line-height: 30px;
            white-space: pre-wrap;
        }
    }
}

</style>
